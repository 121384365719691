<template>
  <section id="email-seeds">
    <div id="email-seed__header">
      <page-header :breadcrumbs="breadcrumbs">
        <template #header>
          <v-col md="12" lg="12" cols="12" class="my-2">
            <v-row class="d-flex" align="center">
              <v-col md="9" lg="9" cols="12">
                <h1
                  class="text-h5 font-weight-large secondary--font text--primary "
                >
                  {{ $dictionary.app.emailSeeds.index.title }}
                </h1>
              </v-col>
              <v-spacer />
              <v-col md="3" cols="12" class="d-flex justify-end">
                <csv-button
                  :data="sortedSeeds"
                  :fileName="csvFileName"
                  :fields="csvFields"
                  :loading="isLoading"
                  :labels="labels"
                />
              </v-col>
            </v-row>
          </v-col>
        </template>
      </page-header>
    </div>
    <div id="email-seeds__wrapper">
      <email-seeds-table
        :emailSeeds="seeds"
        :isDrawerOpen="isDrawerOpen"
        :isLoading="isLoading"
        @open-drawer="openDrawer"
        @get-email-source="getEmailSourceApi"
        @current-items="onCurrentItems"
        :headers="headers"
      />
    </div>
    <div id="email-seeds__drawer">
      <email-seeds-drawer
        :isLoading="isEmailSourceLoading"
        :isDrawerOpen="isDrawerOpen"
        :email-source="emailSource"
        :email-source-data="emailSourceData"
        @toggle-right-drawer="toggleDrawer"
      />
    </div>
  </section>
</template>

<script>
import { EmailSeedsTable } from "@/components/tables";
import { EmailSeedsDrawer } from "@/components/drawers";
import { mapGetters, mapActions } from "vuex";
import { PageHeader } from "@/components/shared";
import { getEmail, getEmailSource } from "@/services";
import { CsvButton } from "@/components/widgets";
import { emailSeedsHeaders as headers } from "@/data/table-headers";
import { getCsvFields } from "@/utils";
import { pick } from "@/utils/common.utils";

export default {
  name: "EmailSeeds",
  /**
   * ---------------- Components ------------------
   */
  components: {
    PageHeader,
    CsvButton,
    EmailSeedsTable,
    EmailSeedsDrawer,
  },
  /**
   * ---------------- Data properties ------------------
   */
  data() {
    return {
      isLoading: false,
      isEmailSourceLoading: false,
      isDrawerOpen: false,
      emailSource: {},
      headers,
      labels: {
        seed_address: "Email Address",
        received_at: "Email Time Stamp",
        mailbox_type: "Status",
        sending_ip: "IP Address",
        mailbox_label: "Category",
      },
      sortedSeeds: [],
      seeds: [],
      emailSourceData: "",
    };
  },
  /**
   * ---------------- Computed properties ------------------
   */
  computed: {
    ...mapGetters({
      filterSeeds: "account/filteredSeeds",
      selectedAccount: "ui/selectedAccount",
    }),
    /**
     * Api request params to get the seeds list
     * @type {Object}
     */
    requestParams() {
      const { seedDomain, id, campaignId } = this.$route.params;

      return {
        campaign_id: campaignId,
        account_id: id,
        mailbox_provider: seedDomain,
      };
    },

    /**
     * Csv File name
     */
    csvFileName() {
      return `seeds_${this.requestParams.mailbox_provider}-${this.requestParams.campaign_id}`;
    },

    /**
     * Breadcrumbs routes and names which will be shown at the top of the page
     * @type {Array}
     */
    breadcrumbs() {
      const { campaignId } = this.$route.params;

      return [
        {
          to: this.selectedAccount.path,
          title: this.$dictionary.app.accountCampaigns.index.title,
          type: this.$appConfig.breadcrumbs.types.route,
        },
        {
          title: this.$dictionary.app.mailboxProviders.index.title,
          type: this.$appConfig.breadcrumbs.types.route,
          to: `${this.selectedAccount.path}/${campaignId}${this.$paths.mailboxProviders}`,
        },
        {
          title: this.$dictionary.app.emailSeeds.index.title,
          type: this.$appConfig.breadcrumbs.types.route,
        },
      ];
    },
    csvFields() {
      return getCsvFields(this.headers);
    },
  },
  /**
   * ------------------- Methods ---------------------
   */
  methods: {
    ...mapActions({
      getCampaignSeeds: "account/getCampaignSeeds",
      setSnackbar: "ui/setSnackbar",
    }),
    /**
     * Fetches seed list from the api resource
     * @param {Object} params Api request params
     */
    async getSeedLists(params) {
      try {
        this.isLoading = true;
        const { seedDomain } = this.$route.params;
        await this.getCampaignSeeds({ ...this.requestParams, ...params });
        this.seeds = this.filterSeeds(seedDomain);
      } catch (error) {
        this.setSnackbar({
          value: true,
          type: this.$appConfig.snackbar.snackbarTypes.error,
          message: this.$errorMessage(error),
        });
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Toggles drawer
     */
    toggleDrawer(val) {
      this.isDrawerOpen = val;
    },
    /**
     * Opens drawer
     */
    openDrawer() {
      this.isDrawerOpen = true;
    },
    /**
     * Fetches the email source from the api
     * @param {String} seed_address  Email address whose email source is to be fetched
     */
    async getEmailSourceApi({ seed_address, id }) {
      try {
        this.isEmailSourceLoading = true;
        const params = { ...this.requestParams, seed_address, id };

        const [emailData] = await Promise.allSettled([
          getEmail(params),
          this.getEmailSourceData(params),
        ]);

        this.emailSource = emailData?.value?.data ?? {};
      } catch (error) {
        this.setSnackbar({
          value: true,
          type: this.$appConfig.snackbar.snackbarTypes.error,
          message: this.$errorMessage(error),
        });
      } finally {
        this.isEmailSourceLoading = false;
      }
    },
    /**
     * Fetches email source of a seed
     */
    async getEmailSourceData(params) {
      try {
        this.isEmailSourceLoading = true;
        const res = await getEmailSource(
          pick(params, ["campaign_id", "account_id", "id"])
        );

        this.emailSourceData = res?.data?.source_email;
      } finally {
        this.isEmailSourceLoading = false;
      }
    },
    /**
     * Update seeds list
     */ onCurrentItems(value) {
      this.sortedSeeds = value;
    },
  },
  /**
  |--------------------------------------------------
  | Created lifecycle hook
  |--------------------------------------------------
  */
  async created() {
    await this.getSeedLists();
  },
};
</script>
