<template>
  <v-navigation-drawer
    right
    fixed
    temporary
    v-model="drawerModel"
    :width="`${drawerWidth}%`"
    class="px-8 py-3 right-nav__drawer"
  >
    <template #prepend>
      <div class="email-seed-drawer__content">
        <v-row class="d-flex">
          <v-col md="12" class="d-flex justify-space-between pb-0">
            <h5 class="text-h5 font-weight-large text--primary">
              Email Preview
            </h5>
            <tooltip #default="{ on, attrs }" name="Close">
              <v-btn
                v-on="on"
                v-bind="attrs"
                class="mx-1 close-drawer"
                icon
                @click.stop="closeDrawer"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </tooltip>
          </v-col>
          <v-col cols="12">
            <v-btn
              text
              outlined
              class="mr-1 btn-light-grey-border text-dark-grey download-email-source"
              :disabled="isLoading"
              @click.stop="downloadEmailSourceFile"
            >
              <v-icon class="text-dark-grey">mdi-download</v-icon>
              <span class="text-dark-grey secondary--font font-weight-bold"
                >Download</span
              >
            </v-btn>

            <v-btn
              class="mx-1 btn-light-grey-border text-dark-grey"
              outlined
              text
              @click.stop="toggleFullScreen"
            >
              <v-icon class="text-dark-grey">{{ drawerIcon }}</v-icon>
              <span
                class="text-dark-grey secondary--font font-weight-bold full-screen"
                >{{ isDrawerFullScreen ? "Exit" : "" }} Full Screen</span
              >
            </v-btn>
          </v-col>
          <template v-if="!isEmpty(emailSource)">
            <v-col cols="6" md="6" :lg="drawerLgGrid" :order-lg="checkOrder(1)">
              <div class="caption text-dark-grey py-1">Email Address</div>
              <div class="body-2 text-black--80 text-break">
                {{ emailSource.seed_address }}
              </div>
            </v-col>
            <v-col cols="6" md="6" lg="1" :order-lg="checkOrder(3)">
              <div class="caption text-dark-grey py-1">Status</div>
              <div class="body-2 text-black--80 text-capitalize">
                {{ emailSource.mailbox_type }}
              </div>
            </v-col>

            <v-col cols="6" :lg="drawerLgGrid" :order-lg="checkOrder(2)">
              <div class="caption text-dark-grey py-1">IP Address</div>
              <div class="body-2 text-black--80">
                {{ emailSource.sending_ip }}
              </div>
            </v-col>
            <v-col cols="6" lg="1" :order-lg="checkOrder(4)">
              <div class="caption text-dark-grey py-1">Category</div>
              <div class="body-2 text-black--80">
                {{ emailSource.mailbox_label }}
              </div>
            </v-col>

            <v-col cols="6" :lg="drawerLgGrid" :order-lg="checkOrder(5)">
              <div class="caption text-dark-grey py-1">Email Time Stamp</div>
              <div class="body-2 text-black--80">
                {{ emailSource.received_at | formatDateToAmPm }}
              </div>
            </v-col>
            <v-col cols="6" :lg="drawerLgGrid" :order-lg="checkOrder(6)">
              <div class="caption text-dark-grey py-1">Email Subject Line</div>
              <tooltip #default="{ on, attrs }" :name="emailSource.subject">
                <div
                  class="body-2 text-black--80 text-break break-line"
                  id="email-subject"
                  v-on="on"
                  v-bind="attrs"
                >
                  {{ emailSource.subject | truncateText(65) }}
                </div>
              </tooltip>
            </v-col>
          </template>
        </v-row>
      </div>

      <v-row
        id="email-seed-drawer__headers"
        no-gutters
        class="white mt-2 mb-16"
      >
        <v-col class="grey lighten-2 px-8 rounded-lg">
          <v-responsive
            :height="getDrawerHeight()"
            width="100%"
            :min-height="getDrawerHeight()"
            :max-height="getDrawerHeight()"
            class="overflow-y-auto"
            id="email-headers"
            style="scroll-behavior: smooth"
          >
            <pre class="white-space-preline">
            <code class="grey lighten-2 body-2 text-black--80 primary--font" color="white"
            >
            {{ emailSourceData }}
            </code>
            </pre>
          </v-responsive>
        </v-col>
      </v-row>
      <loader :loading="isLoading" :size="50" />
    </template>
  </v-navigation-drawer>
</template>

<script>
import { downloadFile } from "@/utils/common.utils";
import { isEmpty } from "@/utils/common.utils";
import { Tooltip } from "../shared";

export default {
  name: "EmailSeedsDrawer",
  /**
   * ---------------- Props ------------------
   */
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    emailSource: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
      default: true,
    },
    emailSourceData: { type: String, required: true, default: "" },
  },
  components: {
    Tooltip,
  },
  /**
   * ---------------- Data properties ------------------
   */
  data() {
    return {
      isDrawerFullScreen: false,
      drawerHeight: null,
    };
  },

  /**
   * ---------------- Custom events ------------------
   */
  emits: ["toggle-right-drawer"],
  /**
   * ---------------- Computed properties ------------------
   */
  computed: {
    /**
     * @model Toogles drawer
     */
    drawerModel: {
      get() {
        return this.isDrawerOpen;
      },
      set(val) {
        this.toogleDrawer(val);
      },
    },
    /**
     * Icon of the drawer
     * @type {String}
     */
    drawerIcon() {
      return this.isDrawerFullScreen ? "mdi-fullscreen-exit" : "mdi-fullscreen";
    },
    /**
     * Dynamic width of the drawer
     * * @type {Number}
     */
    drawerWidth() {
      const [full, medium] = [100, 60];
      return this.isDrawerFullScreen ? full : medium;
    },
    /**
     * Dynamic grid length of the drawer
     * @type {Number}
     */
    drawerLgGrid() {
      return this.isDrawerFullScreen ? 2 : 6;
    },
    addGridOrder() {
      return this.drawerLgGrid === 2;
    },
  },
  watch: {
    emailSourceData(newValue) {
      if (newValue) {
        // Scrolls to the top of email headers div
        const headerEl = document.getElementById("email-headers");
        headerEl && (headerEl.scrollTop = 1);
      }
    },
  },
  /**
   * ------------------- Methods ---------------------
   */
  methods: {
    /**
     * Toogles email drawer
     * @emits toggle-right-drawer
     */
    toogleDrawer(val) {
      this.$emit("toggle-right-drawer", val);
    },
    /**
     * Emist an event in the parent component to close thhe drawer
     */
    closeDrawer() {
      this.toogleDrawer(false);
      this.isDrawerFullScreen && (this.isDrawerFullScreen = false);
    },
    /**
     * Toogles the fullscreen of the drawer
     */
    toggleFullScreen() {
      this.isDrawerFullScreen = !this.isDrawerFullScreen;
    },
    /**
     * Downloads email source in a text file
     */
    downloadEmailSourceFile() {
      const fileName = "email-source";
      this.emailSource &&
        downloadFile(fileName, this.emailSourceData, "text/plain");
    },
    isEmpty,
    /**
     * Calculates the order of the grid elements
     */
    checkOrder(order) {
      return this.addGridOrder ? order : "0";
    },
    /**
     * get Drawer height
     */
    getDrawerHeight() {
      this.$nextTick(() => {
        let tableContent = document.getElementsByClassName(
          "email-seed-drawer__content"
        )[0];
        let header = document.getElementsByTagName("header")[0];
        const marginBottom = 50;
        this.drawerHeight = ` calc(100vh - ${tableContent?.scrollHeight}px - ${header?.scrollHeight}px - ${marginBottom}px)`;
      });
      return this.drawerHeight;
    },
  },
};
</script>
