<template>
  <div class="right-drawer">
    <v-navigation-drawer
      right
      :width="width"
      :fixed="fixed"
      :class="[fixed ? 'right-nav__drawer' : 'drawer-index', drawerClasses]"
      :temporary="fixed"
      :absolute="isAbsolute"
      :app="app"
      :clipped="clipped"
      v-model="drawer"
    >
      <template v-slot:prepend v-if="prepend">
        <v-row no-gutters :class="headerStyle">
          <v-col cols="10" md="10">
            <h5 class="text-h5 text--primary font--secondary font-weight-large">
              {{ title }}
            </h5>
          </v-col>
          <v-col cols="2" md="2" class="d-flex justify-end">
            <tooltip #default="{ on, attrs }" name="Close">
              <v-btn
                class="close-right-drawer-btn"
                v-on="on"
                v-bind="attrs"
                icon
                @click.stop="drawer = false"
              >
                <v-icon class="text-dark-grey">mdi-close</v-icon>
              </v-btn>
            </tooltip>
          </v-col>
        </v-row>
        <div class="subtitle-1 dark-grey py-1" v-if="subTitle">
          {{ subTitle }}
        </div>
        <v-divider v-if="divider" class="my-4 border-light-grey" />
      </template>

      <slot></slot>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { Tooltip } from "../shared";
import { isType } from "@/utils/common.utils";

const [KEYDOWN_EVENT, ESCAPE_KEY] = ["keydown", "Escape"];

/**
 * Right drawer for the filters
 */
export default {
  name: "RightSideBar",
  /**
   * -------------- Components --------------
   */
  components: {
    Tooltip,
  },
  /**
   * Custom events to be emitted by the component
   */
  emits: ["toggle-right-bar"],
  /**
   * Set Props values
   */
  props: {
    title: { type: String, default: "Filter" },
    subTitle: { type: String },
    /**
     * @model v-model property
     */
    isRightDrawerOpen: { type: Boolean, default: false },
    width: { type: String, default: "350" },
    prepend: { type: Boolean, default: false },
    isAbsolute: { type: Boolean, default: false },
    divider: { type: Boolean, default: true },
    headerStyle: { type: String, default: "" },
    fixed: { type: Boolean, default: false },
    app: { type: Boolean, default: false },
    clipped: { type: Boolean, default: false },
    drawerClasses: { type: String, default: "px-6 py-6" },
  },
  /**
   * -------------- Methods --------------
   */
  methods: {
    /**
     * Emits event in the parent component to toggle the drawer
     * @emits toggle-right-bar
     */
    closeRightSideBar(val) {
      this.$emit("toggle-right-bar", val);
    },
    /**
     * Toggles hide-scroll class from the html dom element
     */
    toggleScrollBar(val) {
      const [HTML_TAG, HIDE_SCROLL_CLASS] = ["html", "hide-scroll"];
      const htmlEL = document.querySelector(HTML_TAG);

      if (val) htmlEL.className = HIDE_SCROLL_CLASS;
      else if (!val) htmlEL.classList.remove(HIDE_SCROLL_CLASS);
    },
    /**
     * Closes the drawer on the excape key press in the window
     */
    keyDownHandler(event) {
      if (event.code === ESCAPE_KEY) {
        this.drawer = false;
      }
    },
  },
  /**
   * -------------- Watching Properties --------------
   */
  watch: {
    /**
     * Removes scroll bar from the Html
     */
    isRightDrawerOpen(val) {
      if (isType(val, "boolean") && this.fixed) this.toggleScrollBar(val);
    },
  },
  /**
   * -------------- Computed Properties --------------
   */
  computed: {
    /**
     * Drawer model
     * @model
     */
    drawer: {
      get() {
        return this.isRightDrawerOpen;
      },
      set(val) {
        this.closeRightSideBar(val);
      },
    },
  },
  /**
   * -------------- Mounted Hook --------------
   */
  mounted() {
    window.addEventListener(KEYDOWN_EVENT, this.keyDownHandler);
  },
  /**
   * -------------- Destroyed Hook --------------
   */
  destroyed() {
    window.removeEventListener(KEYDOWN_EVENT, this.keyDownHandler);
  },
};
</script>

<style lang="scss">
// Drawer height calculation
.right-nav__drawer {
  height: calc(100% - 64px) !important;
  top: 64px !important;
}
.drawer-index {
  z-index: 3;
}
</style>
